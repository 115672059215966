<template>
  <div>
    <Pane />
    <a-card class="container">
      <div class="title">
        <span style="padding-right: 4px">常用资料库</span>
        <a-tooltip title="清空">
          <a-icon type="delete" class="delete" @click="deleteOftenUse" />
        </a-tooltip>
      </div>
      <div class="content">
        <div
          class="item"
          v-for="(item, index) in oftenUseList"
          :key="index"
          @click="route(item)"
        >
          <div>{{ item.title }}</div>
        </div>
      </div>

      <div class="title">外部资料库</div>
      <div class="content">
        <div
          class="item"
          v-for="(item, index) in list"
          :key="index"
          @click="route(item)"
        >
          <div>{{ item.title }}</div>
        </div>
      </div>
    </a-card>
  </div>
</template>

<script>
import watermark from "@/mixins/watermark";
export default {
  mixins: [watermark],

  data() {
    return {
      key: "often-use-knowledge",
      oftenUseList: [],
      list: [
        {
          title: "标准规范信息服务平台",
          path: "http://10.23.23.165/std/jzstd.aspx",
        },
        {
          title: "国家建筑标准设计图集信息服务平台",
          path: "http://10.23.23.165/TJ/TJ.htm",
        },
      ],
    };
  },

  mounted() {
    const str = window.localStorage.getItem(this.key);
    if (str) {
      const list = JSON.parse(str);
      list.sort((x, y) => {
        if (x.count > y.count) {
          return -1;
        } else if (x.count < y.count) {
          return 1;
        } else {
          return 0;
        }
      });
      this.oftenUseList = list.filter((item, index) => index < 10);
    }
  },

  methods: {
    route(item) {
      const { key } = this;
      const str = window.localStorage.getItem(key);
      if (str) {
        const list = JSON.parse(str);

        let index = list.findIndex((element) => element.path === item.path);
        const newItem = list[index];
        if (newItem) {
          newItem.count++;
          list.splice(index, 1, newItem);
        } else {
          const obj = {
            ...item,
            count: 1,
          };
          list.push(obj);
        }
        window.localStorage.setItem(key, JSON.stringify(list));
      } else {
        const list = [
          {
            ...item,
            count: 1,
          },
        ];
        window.localStorage.setItem(key, JSON.stringify(list));
      }

      if (item.path.indexOf("http") === 0) {
        window.open(item.path);
      } else {
        this.$router.push(item.path);
      }
    },

    deleteOftenUse() {
      this.oftenUseList = [];
      window.localStorage.removeItem(this.key);
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 12px;
}
.title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 24px;
  position: relative;
  left: 12px;
  line-height: 1em;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: -10px;
    bottom: 0;
    width: 4px;
    background-color: #1890ff;
  }
}
.delete {
  cursor: pointer;
  font-size: 12px;
}
.content {
  margin-bottom: 48px;
  display: flex;
  flex-wrap: wrap;
}

.item {
  margin-right: 16px;
  margin-bottom: 16px;
  font-size: 14px;
  cursor: pointer;
  text-decoration: underline;
}
</style>